<template style="height: 100%;">
	<div class="index _pc-pad-top-170 _mb-pad-top-45">
		<div class="_w-max _b _pc-font-36 title _mb-font-48">{{$t('完善您的收货地址')}}</div>
		<div class="tips1">{{ $t('地址填写后不能更改，请谨慎填写') }}</div>
		<div class="main" >
			<div class="_w-max _flex-row-bet-mid _pc-pad-bottom-20 _mb-display-block">
				<!-- 收件人姓名 -->
				<div class="_flex-1 _mb-flex-row-mid _mb-border-bottom _mb-pad-y-25 _mb-pad-x-35">
					<div class="_pc-font-20 _pc-mar-bottom-10 _mb-font-28 _mb-mar-bottom-0 _mb-mar-right-15">{{ $t('收件者姓名') }}</div>
					<!-- <Input v-model="nickname" size="large" :placeholder="$t('请输入')" clearable style="width: 450px;"/> -->
					<input  type="text" :placeholder="$t('请输入')" v-model="nickname" class="item-input _mb-text-right">
				</div>
				<div class="_mb-display-none" style="padding: 0px 3vw;"></div>
				<!-- 收件人电话 -->
				<div class="_flex-1 _mb-flex-row-mid _mb-border-bottom _mb-pad-y-25 _mb-pad-x-35">
					<div class="_pc-font-20 _pc-mar-bottom-10 _mb-font-28 _mb-mar-bottom-0 _mb-mar-right-15">{{ $t('联系电话') }}</div>
					<input type="text" :placeholder="$t('请输入')" v-model="phone" class="item-input _mb-text-right">
					<!-- <Input v-model="phone" size="large" :placeholder="$t('请输入')" clearable style="width: 450px;" /> -->
				</div>
			</div>
			<div class="_w-max _flex-row-bet-mid _pc-mar-bottom-20 _mb-display-block _mb-mar-bottom-0">
				<!-- 选择国家 -->
				<div class="_flex-1 _mb-flex-row-mid _mb-border-bottom _mb-pad-y-25 _mb-pad-x-35 _mb-flex-row-mid">
					<div class="_pc-font-20 _pc-mar-bottom-10 _mb-font-28 _mb-mar-right-15">{{ $t('state') }}</div>
					<div class="item" style="position: relative;cursor:pointer;">
						<div class="input-box _flex-row-bet-mid _pc-pad-left-15 _pc-pad-right-15" @click="getCountrys(-1)">
							<div class="line-1 _mb-text-right _mb-flex-1 _mb-mar-right-10" :style="country_index!==''?'color:#333':'color:#999'">
								{{country_index!==''? country_name:$t('请选择')}}
							</div>
							<div class="iconfont2 icon-xiala"></div>
						</div>
						<div class="select-box" v-if="country_show">
							<div class="li line-1" v-for="(item,index) in country_list" :key="index"  @click="getCountrys(index,item)">{{item.name}}</div>
						</div>
					</div>
				</div>
				<div class="_mb-display-none" style="padding: 0px 3vw;"></div>
				<!-- 选择城市 -->
				<div class="_flex-1 _mb-flex-row-mid _mb-border-bottom _mb-pad-y-25 _mb-pad-x-35 _mb-flex-row-mid">
					<div class="_pc-font-20 _pc-mar-bottom-10 _mb-font-28 _mb-mar-right-15">{{ $t('city') }}</div>
					<div class="item" style="position: relative;cursor:pointer;">
						<div class="input-box _flex-row-bet-mid _pc-pad-left-15 _pc-pad-right-15" @click="getCitys(-1)">
							<div class="line-1 _mb-text-right _mb-flex-1 _mb-mar-right-10" :style="city_index!==''?'color:#333':'color:#999'">
								{{ city_index!==''? city_name:$t('请选择')}}
							</div>
							<div class="iconfont2 icon-xiala"></div>
						</div>
						<div class="select-box" v-if="city_show">
							<div class="li line-1" v-for="(item,index) in city_list" :key="index"  @click="getCitys(index,item)">{{item.name}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="_w-max _flex-row-bet-mid" v-if="agent_info.id != 14">
				<div class="_w-max _mb-pad-y-25 _mb-pad-x-35">
					<div class="_pc-font-20 _pc-mar-bottom-10 _mb-mar-bottom-20 _mb-font-28">{{ $t('地址') }}</div>
					<textarea class="address-info" id="" cols="30" rows="10" :placeholder="$t('请输入')" v-model="address"></textarea>
				</div>
			</div>
		</div>
		<div class="sub-btn" @click="checkInfo">{{ $t('确认提交') }}</div>
		<template v-if="showmark">
			<div class="mark-box">
				<div class="check-address">
					<div class="title">{{ $t('确认收货地址') }}</div>
					<div class="li">
						<div class="info-box">
							<div class="_pc-w-120 _mb-w-180" style="color: #999;">{{ $t('收件人') }}</div>
							<span>{{ nickname }}</span>
						</div>
						<div class="info-box">
							<div class="_pc-w-120 _mb-w-180" style="color: #999;">{{ $t('联系电话') }}</div>
							<span>{{ phone }}</span>
						</div>
					</div>
					<div class="li">
						<div class="info-box">
							<div class="_pc-w-120 _mb-w-180" style="color: #999;">{{ $t('state') }}</div>
							<span>{{ country_name }}</span>
						</div>
						<div class="info-box">
							<div class="_pc-w-120 _mb-w-180" style="color: #999;">{{ $t('city') }}</div>
							<span>{{ city_name }}</span>
						</div>
					</div>
					<div class="li" style="border-bottom: none;" v-if="agent_info.id != 14">
						<div class="info-box" style="width: 100%;border-bottom: 0;">
							<div class="_pc-w-120 _mb-w-180" style="color: #999;">{{ $t('地址') }}</div>
							<span>{{ address }}</span>
						</div>
					</div>
					<div class="action-btn">
						<button class="_pc-mar-right-40 _mb-mar-right-0" style="background-color: #fff;color: #999;" @click="showmark = false">{{ $t('cancel') }}</button>
						<button style="background-color: #4284DC;color: #fff;" @click="subInfo()">{{ $t('confirm') }}</button>
					</div>
				</div>
			</div>
		</template>
		<template v-if="tologin">
			<div class="mark-to-login">
					<div class="_white-bg _flex-col-mid success-box">
						<div class="_pc-font-32 _mb-font-36 _mb-b" style="color: #333;">{{ $t('注册成功') }}</div>
						<button class="to-login" @click="Login()">{{ $t('登录') }}</button>
					</div>
				</div>
		</template>
	</div>
</template>

<script>
	import { getCountry, cityList, submitRegister, bannerList } from '@/api/jiyun';
	import { setCookies, getCookies } from "@/libs/util";
	export default {
		name: 'addressInfo',
		data() {
			return {
				title: this.$t('page_title.addressInfo'),
				showmark: false,
				tologin: false,
				country_list:[],	// 国家列表
				country_index:'',	// 国家下标
				country_show:false,	// 国家下拉状态
				lang: 'zh_cn',		// 语言
				area_id: 0,			// 地区ID
				country_name: '',	// 地区名称
				city_list: [],		// 城市列表
				city_index: '',
				city_show: false,
				city_id: 0,			// 城市ID
				city_name: '',		// 城市名称
				email: '',
				password: '',
				nickname: '',
				phone: '',
				address: '',
				user_code: '',
				is_consent: '',
				agent_info: {},
			}
		},
		metaInfo() {
			return {
				title: this.title, // set a title
			};
		},
		created() {
			this.lang = localStorage.getItem('think-lang') || 'zh_cn';
			this.is_consent = getCookies('consent') == 'true' ? true : false;
			// if(!this.is_consent) {
			// 	// this.$Message.info(this.$t('请阅读并同意协议'));
			// 	// setTimeout(()=>{
			// 	// 	this.$router.replace({
			// 	// 		path: '/agreement',
			// 	// 	})
			// 	// },2000)
			// }else{
				this.email = this.$route.params.email;
				this.password = this.$route.params.password;
				this.user_code = this.$route.params.user_code;
				// if(!this.email || !this.password ){
				// 	this.$Message.info(this.$t('流程不符，请先注册'));
				// 	this.$router.replace({
				// 		path: '/register',
				// 	})
				// }
				this.getCountry();
			// }
			this.getAgentInfo();
		},
		methods: {
			// 代理信息
			getAgentInfo(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
					console.log('agent',this.agent_info)
				}).catch((err)=>{
					this.$Message.error(err.msg);
				})
			},
			// 获取城市列表
			getCity(){
				let that = this;
				let param = {
					pid: that.area_id,
					domain_url: window.location.origin
				}
				cityList(param).then((res)=>{
					that.city_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 选择城市
			getCitys(index,city){
				if(!this.area_id) return this.$Message.error(this.$t('请先选择国家或地区'))
				if(index==-1){
					this.city_show = !this.city_show;
				} else {
					this.city_show = !this.city_show;
					this.city_index = index;
					this.city_id = city.id;
					this.city_name = city.name;
				}
			},
			// 获取国家列表
			getCountry(){
				let param = {
					domain_url: window.location.origin
				}
				getCountry(param).then((res)=>{
					this.country_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 选择国家
			getCountrys(index,country) {
				if(index==-1){
					this.country_show = !this.country_show;
				} else {
					this.country_show = !this.country_show;
					this.country_index = index;
					this.area_id = country.id;
					this.country_name = country.name;
					this.city_id = 0;
					this.city_name = '';
					this.getCity();
				}
			},
			// 确认收货地址
			checkInfo() {
				let that = this;
				that.showmark = true;
			},
			// 提交注册信息
			subInfo() {
				let that = this;
				let param = {
					email: that.email,
					password: that.password,
					nickname: that.nickname,
					phone: that.phone,
					area_id: that.area_id,
					area_name: that.country_name,
					province_id: that.city_id,
					province_name: that.city_name,
					addres: that.address,
					domain_url: window.location.origin,
					user_code: that.user_code
				}
				submitRegister(param).then((res)=>{
					setCookies("consent",false)
					that.showmark = false;
					that.tologin = true;
				}).catch((err)=>{
					this.$Message.error(err.msg);
				})
			},
			// 登录
			Login() {
				let that = this;
				that.tologin = false;
				that.$router.replace({
					path: '/login',
				})
			}
		}
	}
</script>

<style scoped>
	div {
		user-select:none;
	}
	.select-box {
		position: absolute;
		width: 80%;
		min-height: 50px;
		max-height: 300px;
		overflow: hidden;
		overflow-y: scroll;
		z-index: 99;
		top: 50px;
		left: 0;	
		background-color: #ffffff;
	}
	.select-box .li {
		width: 100%;
		height: 50px;
		padding: 0px 30px;
		line-height: 50px;
		font-size: 18px;
	}
	.line-1 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.input-box {
		width: 100%;
		height: 50px;
		border-radius: 8px;
		background: #FFFFFF;
		cursor: pointer;
	}
	.index {
		width: 100%;
		min-height: 100%;
		background-image: url(../../assets/image/hangda/logo/logo_bg.png) !important;
		background-size: 100% 100% !important;
		background-repeat: no-repeat !important;
		/* overflow: scroll;
		overflow-x: hidden; 
		scrollbar-width: none;
		-ms-overflow-style: none; */
		padding-bottom: 80px;
		display: flex;
		flex-direction: column;
		
		padding-left: 20vw;
		padding-right: 20vw;
	}

	.index .title {
		color: #333;
	}

	.index .tips1 {
		color: #C81920;
		font-size: 16px;
		margin-top: 13px;
	}

	.index .main {
		margin-top: 40px;
		width: 100%;
	}

	input {
		border-radius: 7px;
		background-color: #fff;
		width: 450px;
		padding: 15px 10px;
		margin-bottom: 10px;
		
	}

	.address-info {
		width: 100%;
		height: 105px;
		border-radius: 7px;
		background-color: #ffffff;
		outline: none;
		border: none;
		padding: 15px 10px;
	}

	.sub-btn {
		width: 424px;
		height: 62px;
		background: #4284DC;
		border-radius: 12px;
		margin: 0 auto;
		margin-top: 20px;
		color: #fff;
		font-size: 20px;
		text-align: center;
		line-height: 62px;
		cursor: pointer;
	}

	.sub-btn:hover {
		opacity: 0.6;
	}

	.mark-box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		padding-left: 45px;
		padding-right: 45px;
	}

	.mark-box .check-address {
		width: 912px;
		height: 470px;
		border-radius: 16px;
		background-color: #fff;
		padding: 52px 45px;
	}

	.mark-box .check-address .title {
		font-size: 36px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.mark-box .check-address .li {
		width: 100%;
		height: 80px;
		border-bottom: 1px solid rgba(153, 153, 153, 0.3);
		display: flex;
		align-items: center;
	}

	.mark-box .check-address .li .info-box {
		width: 50%;
		/* height: 100%; */
		display: flex;
		align-items: center;
		font-size: 20px;
	}

	.action-btn {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.action-btn button:first-child {
		border: 1px solid #999;
	}

	.action-btn button {
		width: 264px;
		height: 62px;
		border-radius: 8px;
		font-size: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		margin-top: 20px;
	}

	.mark-to-login {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}

	.to-login {
		width: 279px;
		/* height: 62px; */
		background: #4284DC;
		border-radius: 12px;
		font-size: 20px;
		color: #ffffff;
		padding-top: 15px;
		padding-bottom: 15px;
		border: none;
		margin-top: 20px;
	}

	.item-input{
		padding-left: 15px;width: 100%;
	}

	.success-box{
		border-radius: 16px;
		padding: 50px;
	}

	@media screen and (max-device-width: 768px) {
		.index {
			padding-bottom: 2.28311vh;
			padding-left: 3.33333vw;
			padding-right: 3.33333vw;
		}

		.index .tips1 {
			font-size: 3.73333vw;
			margin-top: 1.14155vh;
		}

		.index .main {
			margin-top: 2.66362vh;
			background-color: #fff;
			border-radius: 6px;
		}

		.item-input{
			flex: 1;
			padding-left:0;
		}

		input {
			border-radius: 0px;
			width: 100%;
			padding: 0px;
			margin-bottom: 0px;
		}

		.item{
			padding-left: 0px;
			width: unset;
			flex: 1;
		}

		.input-box {
			height: unset;
		}

		.select-box {
			width: 100%;
			min-height: 3.80518vh;
			max-height: 22.83105vh;
			top: 3.80518vh;
			left: 0;	
			background-color: #ffffff;
			box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
		}
		.select-box .li {
			width: 100%;
			height: 3.80518vh;
			padding: 0px 3.33333vw;
			line-height: 3.80518vh;
			font-size: 3.73333vw;
		}

		.address-info {
			height: 9.89346vh;
			border-radius: 7px;
			padding: 1.52207vh 4vw;
			background-color: rgba(247, 247, 247, 1);
		}


		.sub-btn {
			width: 100%;
			height: 5.70776vh;
			border-radius: 6px;
			margin-top: 1.90259vh;
			font-size: 3.73333vw;
			line-height: 5.70776vh;
		}

		.success-box{
			border-radius: 8px;
			padding: 8.66667vw;
		}

		.to-login {
			width: 33.33333vw;
			border-radius: 6px;
			font-size: 3.73333vw;
			padding-top: 1.90259vh;
			padding-bottom: 1.90259vh;
			margin-top: 2.28311vh;
		}


		.mark-box {
			padding-left: 0px;
			padding-right: 0px;
			align-items:flex-end;
		}


		.mark-box .check-address {
			width: 100%;
			height: unset;
			border-radius: 16px 16px 0px 0px;
			background-color: #fff;
			padding: 3.42466vh 3.33333vw;
		}

		.mark-box .check-address .title {
			font-size: 6.4vw;
			margin-bottom: 0.76104vh;
		}

		.mark-box .check-address .li {
			width: 100%;
			height: unset;
			border-bottom: 0;
			display: block;
		}

		.mark-box .check-address .li .info-box {
			width: 100%;
			/* height: 100%; */
			display: flex;
			align-items: center;
			font-size: 3.73333vw;
			padding: 2.66362vh 0;
			border-bottom: 1px solid rgba(153, 153, 153, 0.3);
		}

		.action-btn button{
			width: unset;
			flex:1;
			height: 5.70776vh;
			font-size: 3.73333vw;
			margin-top: 1.52207vh;
		}
		.action-btn button[data-v-52c830da]:first-child{
			margin-right: 4.66667vw;
		}
	}
</style>